export default {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  breakpoints: ["40em", "56em", "64em"], // [`750px`, `1000px`],
  fonts: {
    body: "Barlow, normal",
    heading: "Barlow Condensed, normal",
    monospace: "Menlo, monospace",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    light: 300,
    body: 400,
    heading: 700,
    bold: 700,
  },
  colors: {
    text: "#000",
    textMuted: ["#AAAFAF", "#4b4e56"],
    faint: "#232323",
    // border: "#333",
    primary: "#39B3B4",
    secondary: `#E8903D`, // #008BFF
    white: `#FFFFFF`,
    red: "#5A1635",
    blue: `rgb(50, 42, 101)`,
    background: `#FFFFFF`,
    modes: {
      light: {},
    },
  },
  radii: [0, 2, 4, 8, 16],
  styles: {
    root: {
      p: {
        fontFamily: `body`,
        fontSize: [2, 3],
        lineHeight: 2,
      },
      strong: {
        color: `secondary`,
      },
      a: {
        fontFamily: `body`,
        color: `secondary`,
        fontWeight: 600,
      },
      hr: {
        borderColor: `primary`,
        borderStyle: `solid`,
        borderWidth: `3px`,
        my: 4,
      },
      blockquote: {
        p: {
          fontFamily: `heading`,
          fontSize: 4,
          lineHeight: [1, 2],
        },
        textTransform: `uppercase`,
        borderLeftStyle: `solid`,
        borderLeftWidth: `8px`,
        borderLeftColor: `primary`,
        paddingLeft: [`15px`, `20px`],
        // paddingY: 2,
      },
      li: {
        fontSize: [2, 3],
        lineHeight: 2,
        mb: 2,
      },
      h1: {
        fontFamily: `heading`,
        fontSize: 6,
        lineHeight: 1,
      },
      h2: {
        fontFamily: `heading`,
        fontSize: 5,
        lineHeight: 1,
      },
      h3: {
        fontFamily: `heading`,
        fontSize: 4,
        lineHeight: 1,
      },
      h4: {
        fontFamily: `heading`,
        fontSize: 3,
        lineHeight: 1,
      },
      h5: {
        fontFamily: `heading`,
        fontSize: 2,
        lineHeight: 1,
      },
    },
  },
  button: {
    default: {
      textDecoration: `none`,
      letterSpacing: 2,
      textTransform: `uppercase`,
      fontWeight: 700,
      fontFamily: "body",
      fontSize: `2`,
      py: `2`,
      px: `4`,
      color: `white`,
      borderRadius: `2`,
      display: `flex`,
      alignItems: `center`,
      justifyContent: `center`,
      cursor: `pointer`,
      width: `fit-content`,
      "&:disabled": {
        opacity: 0.65,
      },
    },
    link: {
      textDecoration: `none`,
      color: `black`,
      fontSize: 3,
      px: `3`,
      py: `2`,
      fontWeight: 500,
      transition: `0.3s all`,
      // borderRadius: `3`,
      display: `flex`,
      alignItems: `center`,
      "&:hover": {
        color: `primary`,
      },

      faint: {
        textDecoration: `none`,
        color: `textMuted`,
        px: `3`,
      },
    },
    action: {
      textDecoration: `none`,
      color: `white`,
      fontWeight: 600,
      fontSize: `2`,
      px: `3`,
      py: `2`,
      transition: `0.3s all`,
      borderRadius: `3`,
      borderStyle: `solid`,
      borderWidth: `2px`,
      borderColor: `secondary`,
      display: `flex`,
      alignItems: `center`,
      bg: `secondary`,
      "&:hover": {
        bg: `white`,
        color: `secondary`,
      },
    },
    social: {
      // p: 2,
      color: `faint`,
      mx: 2,
    },
  },
  input: {
    default: {
      fontSize: `2`,
      p: `3`,
      my: 2,
      borderRadius: `5px`,
      width: `100%`,
      textAlign: `center`,
      mb: 4,
      "&:focus,  &:active": {
        borderColor: `secondary`,
      },
    },
    checkbox: {
      width: `30px`,
      height: `30px`,
    },
    newsletter: {
      fontSize: `2`,
      p: `3`,
      my: 2,
      bg: `rgb(0,0,0,0.3)`,
      color: `white`,
      border: `0px solid white`,
      borderRadius: `5px`,
      "&::placeholder": {
        color: `white`,
      },
      button: {
        textDecoration: `none`,
        textTransform: `uppercase`,
        fontFamily: "body",
        fontSize: `2`,
        px: `3`,
        py: `2`,
        borderRadius: `3`,
        transition: `0.3s all`,
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        cursor: `pointer`,
        width: `fit-content`,
        bg: `red`,
        color: `white`,
        mt: 3,
        mx: `auto`,
        "&:disabled": {
          opacity: 0.65,
        },
        "&:focus,  &:active": {
          borderColor: `secondary`,
        },
      },
      checkbox: {
        color: `white`,
        borderRadius: 0,
        "input:checked ~ &": {
          color: `white`,
        },
        "input:focus ~ &": {
          color: `white`,
          bg: `secondary`,
        },
      },
    },
  },
  donationBox: {
    borderStyle: `solid`,
    borderColor: `primary`,
    borderWidth: `5px`,
    borderRadius: `15px`,
    py: 4,
    px: 2,
    mx: [2, 4],
    textAlign: `center`,
  },
}
